import React from 'react';
import { IYoutubeData, YoutubePlacementKey } from '../types/youtube-preview.type';
import { YoutubePreview } from '../components';

export const YoutubeFactory = (placementKey: YoutubePlacementKey, youtubeData: IYoutubeData) => {
    let PreviewComponent: React.FC<IYoutubeData> | React.JSX.IntrinsicAttributes;

    switch (placementKey) {
        case 'youtube-post':
            PreviewComponent = YoutubePreview;
            break;
        default:
            return <div>Ad type not available</div>;
    }

    return <PreviewComponent {...youtubeData} />;
};
