import React from 'react';
import { useResources } from 'components/data/Resources/hooks/useResources';
import { ISnapchatData, SnapchatPlacementKey } from '../types/snapchat-preview.type';
import { SnapchatCollectionPreview, SnapchatSinglePreview, SnapchatStoryPreview } from '../components';

export const SnapchatFactory = (placementKey: SnapchatPlacementKey, snapchatData: ISnapchatData) => {
    const { resources, loading } = useResources<{ snapchat_cta: Record<string, string> }>(['snapchat_cta']);

    // Map the CTA to the correct label
    if (!loading) {
        snapchatData.cta = resources?.snapchat_cta?.[snapchatData.cta] || snapchatData.cta;
    }

    let PreviewComponent: React.FC<ISnapchatData> | React.JSX.IntrinsicAttributes;

    switch (placementKey) {
        case 'snapchat-ad':
            PreviewComponent = SnapchatSinglePreview;
            break;
        case 'snapchat-story':
            PreviewComponent = SnapchatStoryPreview;
            break;
        case 'snapchat-collection':
            PreviewComponent = SnapchatCollectionPreview;
            break;
        default:
            return <div>Ad type not available</div>;
    }

    return <PreviewComponent {...snapchatData} />;
};
