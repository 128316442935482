import React from 'react';
import { BrickSocialPlatform } from 'components/bricks/types/brick.type';
import { MetaFactory } from './components/meta/factories/meta.factory';
import { LinkedinFactory } from './components/linkedin/factories/linkedin.factory';
import { MetaPlacementKey } from './components/meta/types/meta-preview.type';
import { LinkedinPlacementKey } from './components/linkedin/types/linkedin-preview.type';
import { TikTokPlacementKey } from './components/tiktok/types/tiktok-preview.type';
import { TikTokFactory } from './components/tiktok/factories/tiktok.factory';
import { SnapchatPlacementKey } from './components/snapchat/types/snapchat-preview.type';
import { SnapchatFactory } from './components/snapchat/factories/snapchat.factory';
import { AdSetupFrameType, PlacementKey } from './social-preview.type';
import { YoutubeFactory } from './components/youtube/factories/youtube.factory';
import { YoutubePlacementKey } from './components/youtube/types/youtube-preview.type';

export const SocialPreviewFactory = (platform: BrickSocialPlatform, placementKey: PlacementKey, props: any, frameType: AdSetupFrameType) => {
    switch (platform) {
        case 'meta':
            return MetaFactory(placementKey as MetaPlacementKey, props, frameType);
        case 'linkedin':
            return LinkedinFactory(placementKey as LinkedinPlacementKey, props);
        case 'tiktok':
            return TikTokFactory(placementKey as TikTokPlacementKey, props);
        case 'snapchat':
            return SnapchatFactory(placementKey as SnapchatPlacementKey, props);
        case 'youtube':
            return YoutubeFactory(placementKey as YoutubePlacementKey, props);
        default:
            return <div>Another Platform</div>;
    }
};
