import React, { useState } from 'react';
import { Avatar } from '@mui/material';
import BrandSettings from 'components/data/BrandSettings';
import Button from 'components/ui-components-v2/Button';
import FallbackLogo from '../../../../../../../../static/fallback-logo-cape.png';
import shareArrow from '../images/share-arrow.svg';

import '../styles/snapchat-wrapper.scss';

interface SnapchatWrapperProps {
    asset: React.ReactNode;
    cta: string;
    link: string;
    name: string;
    headline: string;
    showBottomCard?: boolean;
    children?: React.ReactNode;
}

const SnapchatWrapper: React.FC<SnapchatWrapperProps> = ({ asset, cta, link, name, headline, showBottomCard = true, children }) => {
    const advertiser = BrandSettings.getAdvertiserData();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="snapchat-wrapper" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="snapchat-wrapper__gradient-top"></div>
            <div className="snapchat-wrapper__asset-wrapper">{asset}</div>
            <div className="snapchat-wrapper__header">
                <div className="snapchat-wrapper__header__company">
                    <Avatar src={advertiser?.logo || FallbackLogo} className="snapchat-wrapper__header__company__avatar" />
                    <div className="snapchat-wrapper__header__company__info">
                        <div className="snapchat-wrapper__header__company__info__name">{advertiser?.name || 'Cape'}</div>
                        <div className="snapchat-wrapper__header__company__info__description">{headline}</div>
                    </div>
                </div>
            </div>

            <div className={`snapchat-wrapper__bottom-section ${isHovered ? 'hidden' : ''}`}>
                {showBottomCard && (
                    <div className="snapchat-wrapper__bottom-card">
                        <div className="snapchat-wrapper__bottom-card__info">
                            <div className="snapchat-wrapper__bottom-card__info__company-name">{advertiser?.name || 'Company'}</div>
                            <div className="snapchat-wrapper__bottom-card__info__description">{headline}</div>
                            <a href={link} target="_blank" rel="noopener noreferrer" className="snapchat-wrapper__bottom-card__info__link">
                                {link}
                            </a>
                        </div>
                    </div>
                )}
                <div className="snapchat-wrapper__bottom-row">
                    <a href={link} target="_blank" rel="noopener noreferrer" className="snapchat-wrapper__bottom-row__cta-link">
                        <Button className="snapchat-wrapper__bottom-row__cta-button">{cta}</Button>
                    </a>
                    <div className="snapchat-wrapper__bottom-row__share-arrow">
                        <img src={shareArrow} alt="Share" />
                    </div>
                </div>
            </div>

            <span className={`snapchat-wrapper__ad-label ${isHovered ? 'hidden' : ''}`}>Ad</span>

            {children}
            <div className={`snapchat-wrapper__gradient-bottom ${isHovered ? 'hidden' : ''}`}></div>
        </div>
    );
};

export default SnapchatWrapper;
