import React from 'react';
import SnapchatWrapper from './snapchat-wrapper';
import { ISnapchatData } from '../types/snapchat-preview.type';

import '../styles/snapchat-collection-preview.scss';

const SnapchatCollectionPreview: React.FC<ISnapchatData> = ({ cta, items = [] }) => {
    const { name, headline, link = '' } = items?.[0] || '';

    // Ensure three items for display, or placeholders if necessary/missing
    const displayItems = [...items.slice(0, 3)];
    while (displayItems.length < 3) {
        displayItems.push({
            asset: null,
            name: '',
            headline: '',
            link: ''
        });
    }

    return (
        <SnapchatWrapper
            asset={items[0]?.asset || <div className="snapchat-collection__image-placeholder">Select media</div>}
            cta={cta}
            link={link}
            name={name || ''}
            headline={headline || ''}
            showBottomCard={false}>
            <div className="snapchat-collection__collection-card">
                <div className="snapchat-collection__collection-card__items">
                    {displayItems.map((item, index) => (
                        <div key={index} className="snapchat-collection__collection-card__items__item">
                            {item.asset ? item.asset : <div className="snapchat-collection__collection-card__items__placeholder">Select media</div>}
                        </div>
                    ))}
                </div>
            </div>
        </SnapchatWrapper>
    );
};

export default SnapchatCollectionPreview;
