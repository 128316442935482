import React from 'react';
import { SocialPreviewFactory } from './social-preview.factory';
import { ISocialPreview } from './social-preview.type';

interface Props {
    socialPreview: ISocialPreview;
}

const SocialPreview: React.FC<Props> = ({ socialPreview }) => {
    const { platform, placementKey, data, frameType } = socialPreview;
    return <>{SocialPreviewFactory(platform, placementKey, data, frameType)}</>;
};

export default SocialPreview;
