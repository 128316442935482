import React, { useState } from 'react';
import BrandSettings from 'components/data/BrandSettings';
import Avatar from 'components/ui-components-v2/Avatar';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import Typography from 'components/ui-components-v2/Typography';
import skipIcon from '../images/youtube-next-icon.svg';
import previousIcon from '../images/youtube-previous-icon.svg';
import pauseIcon from '../images/youtube-pause-icon.svg';
import like from '../images/actions-like.svg';
import share from '../images/actions-share.svg';
import download from '../images/actions-download.svg';
import FallbackLogo from '../../../../../../../../static/fallback-logo-cape.png';
import { IYoutubeData } from '../types/youtube-preview.type';
import MetaHelpers from '../../meta/helpers/meta.helpers';

import '../styles/youtube-preview.scss';

const YouTubePreview: React.FC<IYoutubeData> = ({ items }) => {
    const { snippet, asset } = items?.[0] || {};
    const { title = '', description = '' } = snippet || {};
    const advertiser = BrandSettings.getAdvertiserData();
    const [isHovered, setIsHovered] = useState(false);
    const [showFullText, setShowFullText] = useState(false);

    const handleShowFullText = () => {
        setShowFullText(true);
    };

    return (
        <div className="youtube-preview" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="youtube-preview__asset-wrapper">
                {asset ? (
                    <>
                        <div className="youtube-preview__asset-wrapper__asset">{asset}</div>
                        <div className={`youtube-preview__asset-wrapper__timing ${isHovered ? 'hidden' : ''}`}>22:54</div>

                        <div className={`youtube-preview__asset-wrapper__controls ${isHovered ? 'hidden' : ''}`}>
                            <div className="youtube-preview__asset-wrapper__controls__icon-container youtube-preview__asset-wrapper__controls__icon-container__previous">
                                <img src={previousIcon} alt="Previous" className="youtube-preview__asset-wrapper__controls__icon" />
                            </div>
                            <div className="youtube-preview__asset-wrapper__controls__icon-container youtube-preview__asset-wrapper__controls__icon-container__pause">
                                <img src={pauseIcon} alt="Pause" className="youtube-preview__asset-wrapper__controls__icon" />
                            </div>
                            <div className="youtube-preview__asset-wrapper__controls__icon-container youtube-preview__asset-wrapper__controls__icon-container__next">
                                <img src={skipIcon} alt="Skip ad" className="youtube-preview__asset-wrapper__controls__icon" />
                            </div>
                        </div>
                    </>
                ) : (
                    <Typography variant="body2" color="textSecondary" className="youtube-preview__placeholder">
                        {Translation.get('adSetup.creativesPreview.selectSuitableMedia', 'bricks')}
                    </Typography>
                )}
            </div>

            <div className="youtube-preview__progress-bar">
                <div className="youtube-preview__progress-bar__filled"></div>
            </div>

            <div className="youtube-preview__actions">
                <img src={like} alt="Like" className="youtube-preview__actions__icon" />
                <img src={share} alt="Share" className="youtube-preview__actions__icon" />
                <img src={download} alt="Download" className="youtube-preview__actions__icon" />
            </div>

            <div className="youtube-preview__text">
                <div className="youtube-preview__text__title">{MetaHelpers.transformText(title || '', showFullText, handleShowFullText, 72)}</div>
                <div className="youtube-preview__text__description">{MetaHelpers.transformText(description || '', showFullText, handleShowFullText, 208)}</div>
                <div className="youtube-preview__text__details">
                    1,687 views • Premiered Sept 2, 2024 <span className="youtube-preview__text__details__hashtag">#cape</span>
                </div>
            </div>

            <div className="youtube-preview__info">
                <Avatar src={advertiser?.logo || FallbackLogo} alt="Logo" />
                <div className="youtube-preview__info__text">
                    <div className="youtube-preview__info__text__name">{advertiser?.name || 'Cape'}</div>
                    <div className="youtube-preview__info__text__subscribers">289K subscibe</div>
                </div>
                <Button className="youtube-preview__info__cta">Subscribe</Button>
            </div>
        </div>
    );
};

export default YouTubePreview;
