import React from 'react';
import { useResources } from 'components/data/Resources/hooks/useResources';
import { IMetaData, MetaPlacementKey } from '../types/meta-preview.type';
import { AdSetupFrameType } from '../../../social-preview.type';
import {
    FacebookFeedSinglePreview,
    FacebookFeedMultiplePreview,
    FacebookMarketplacePreview,
    FacebookStoryPreview,
    FacebookFeedVideoPreview,
    FacebookInstreamVideoPreview,
    FacebookRightColumnPreview,
    FacebookSearchPreview,
    FacebookReelsPreview,
    FacebookAdsOnReelsPreview,
    InstagramFeedPreview,
    InstagramFeedMultiplePreview,
    InstagramExploreHomePreview,
    InstagramStoryPreview,
    InstagramSearchPreview,
    InstagramReelsPreview
} from '../components';

export const MetaFactory = (placementKey: MetaPlacementKey, metaData: IMetaData, frameType: AdSetupFrameType) => {
    const { resources, loading } = useResources<{ facebook_cta: Record<string, string> }>(['facebook_cta']);

    // Map the CTA to the correct label
    if (!loading) {
        metaData.cta = resources?.facebook_cta?.[metaData.cta] || metaData.cta;
    }

    let PreviewComponent: React.FC<IMetaData> | React.JSX.IntrinsicAttributes;
    let additionalProps = {};

    switch (placementKey) {
        case 'facebook-feed':
            PreviewComponent = frameType === 'multi' ? FacebookFeedMultiplePreview : FacebookFeedSinglePreview;
            break;
        case 'facebook-profile_feed':
            PreviewComponent = FacebookFeedSinglePreview;
            additionalProps = { isProfile: true };
            break;
        case 'facebook-marketplace':
            PreviewComponent = FacebookMarketplacePreview;
            break;
        case 'facebook-story':
            PreviewComponent = FacebookStoryPreview;
            break;
        case 'facebook-video_feeds':
            PreviewComponent = FacebookFeedVideoPreview;
            break;
        case 'facebook-instream_video':
            PreviewComponent = FacebookInstreamVideoPreview;
            break;
        case 'facebook-right_hand_column':
            PreviewComponent = FacebookRightColumnPreview;
            break;
        case 'facebook-search':
            PreviewComponent = FacebookSearchPreview;
            break;
        case 'facebook-facebook_reels':
            PreviewComponent = FacebookReelsPreview;
            break;
        case 'facebook-ads_on_reels':
            PreviewComponent = FacebookAdsOnReelsPreview;
            break;
        case 'instagram-explore':
            PreviewComponent = frameType === 'multi' ? InstagramFeedMultiplePreview : InstagramFeedPreview;
            break;
        case 'instagram-stream':
            PreviewComponent = frameType === 'multi' ? InstagramFeedMultiplePreview : InstagramFeedPreview;
            break;
        case 'instagram-profile_feed':
            PreviewComponent = InstagramFeedPreview;
            additionalProps = { isProfile: true };
            break;
        case 'instagram-explore_home':
            PreviewComponent = InstagramExploreHomePreview;
            break;
        case 'instagram-story':
            PreviewComponent = InstagramStoryPreview;
            break;
        case 'instagram-ig_search':
            PreviewComponent = InstagramSearchPreview;
            break;
        case 'instagram-reels':
            PreviewComponent = InstagramReelsPreview;
            break;
        default:
            return <div>Ad type not available</div>;
    }

    if (!PreviewComponent) {
        return <div>The frame type not available for this ad</div>;
    }

    return <PreviewComponent {...metaData} {...additionalProps} />;
};
