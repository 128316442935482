import React from 'react';
import { useResources } from 'components/data/Resources/hooks/useResources';
import { ITikTokData, TikTokPlacementKey } from '../types/tiktok-preview.type';
import { TikTokFeedPreview } from '../components';

export const TikTokFactory = (placementKey: TikTokPlacementKey, tiktokData: ITikTokData) => {
    const { resources, loading } = useResources<{ tiktok_cta: Record<string, string> }>(['tiktok_cta']);

    // Map the CTA to the correct label
    if (!loading) {
        tiktokData.cta = resources?.tiktok_cta?.[tiktokData.cta] || tiktokData.cta;
    }

    let PreviewComponent: React.FC<ITikTokData> | React.JSX.IntrinsicAttributes;

    switch (placementKey) {
        case 'tiktok-feed':
            PreviewComponent = TikTokFeedPreview;
            break;
        default:
            return <div>Ad type not available</div>;
    }

    return <PreviewComponent {...tiktokData} />;
};
