import React, { useState, useEffect, useCallback } from 'react';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import LinearProgress from 'components/ui-components-v2/LinearProgress';
import useProgressTimer from '../../meta/helpers/useProgressTimer';
import SocialPreviewsHelpers from '../../../helpers/social-previews.helpers';
import SnapchatWrapper from './snapchat-wrapper';
import { ISnapchatData } from '../types/snapchat-preview.type';

import '../styles/snapchat-story-preview.scss';

const SnapchatStoryPreview: React.FC<ISnapchatData> = ({ cta, items = [] }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { selectedFrame } = useAdSetupContext();
    const { progress, resetProgress } = useProgressTimer(currentIndex);

    useEffect(() => {
        setCurrentIndex(selectedFrame);
    }, [selectedFrame]);

    const nextSlide = useCallback(() => {
        if (currentIndex < items?.length - 1) {
            setCurrentIndex(currentIndex + 1);
            resetProgress();
        }
    }, [currentIndex, items, resetProgress]);

    const prevSlide = useCallback(() => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            resetProgress();
        }
    }, [currentIndex, resetProgress]);

    const getProgressValue = useCallback(
        (index: number) => {
            if (index === currentIndex) {
                return progress;
            }
            if (index < currentIndex) {
                return 100;
            }
            return 0;
        },
        [currentIndex, progress]
    );

    const formattedLink = SocialPreviewsHelpers.formatLink(items?.[currentIndex]?.link || '');

    return (
        <SnapchatWrapper
            asset={items?.[currentIndex]?.asset}
            cta={cta}
            link={formattedLink}
            name={items?.[currentIndex]?.name || ''}
            headline={items?.[currentIndex]?.headline || ''}>
            <div className="snapchat-story__progress-wrapper">
                {items?.map((_, index) => (
                    <LinearProgress key={index} variant="determinate" value={getProgressValue(index)} className="snapchat-story__progress" />
                ))}
            </div>

            <Button className="snapchat-story__see-more">See 1 more</Button>

            {items?.length > 1 && (
                <div className="snapchat-story__navigation">
                    {currentIndex > 0 && (
                        <div className="snapchat-story__arrow-left" onClick={prevSlide}>
                            <Icon fontSize="large">chevron_left</Icon>
                        </div>
                    )}
                    {currentIndex < items?.length - 1 && (
                        <div className="snapchat-story__arrow-right" onClick={nextSlide}>
                            <Icon fontSize="large">chevron_right</Icon>
                        </div>
                    )}
                </div>
            )}
        </SnapchatWrapper>
    );
};

export default SnapchatStoryPreview;
