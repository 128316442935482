import React from 'react';
import '../styles/main.scss';

interface WordObject {
    word: string;
    hashtag?: boolean;
    showMore?: boolean;
}

class MetaHelpers {
    /**
     * Transforms the input text and provides a callback to show full text.
     * @param {string} text - The text to be transformed.
     * @param {boolean} showFullText - Flag to indicate if the full text should be shown.
     * @param {() => void} showFullTextCallback - Callback to show the full text.
     * @returns {JSX.Element | null} The transformed text as a JSX element.
     */
    static transformText = (text: string, showFullText: boolean, showFullTextCallback: () => void, maxLength: number) => {
        if (!text) return null;

        const textArray = MetaHelpers.splitText(text);
        const mappedTextArray = MetaHelpers.mapTextArray(textArray);
        const filteredWordsArray = MetaHelpers.filterWords(mappedTextArray, showFullText, maxLength);

        return <div>{filteredWordsArray.map((word, index) => MetaHelpers.renderWordFacebook(word, index, showFullTextCallback))}</div>;
    };

    /**
     * Transforms the input message for Instagram preview and provides a callback to show full message.
     * @param {string} message - The message to be transformed.
     * @param {string} name - The name (headline) to be included in the message.
     * @param {boolean} showFullMessage - Flag to indicate if the full message should be shown.
     * @param {() => void} showFullMessageCallback - Callback to show the full message.
     * @returns {JSX.Element | null} The transformed message as a JSX element.
     */
    static transformMessage = (message: string, showFullMessage: boolean, showFullMessageCallback: () => void) => {
        if (!message) return null;

        const textArray = MetaHelpers.splitText(message);
        const mappedTextArray = MetaHelpers.mapTextArray(textArray);
        const filteredWordsArray = MetaHelpers.filterWords(mappedTextArray, showFullMessage, 85);

        return <div>{filteredWordsArray.map((word, index) => MetaHelpers.renderWordInstagram(word, index, showFullMessageCallback))}</div>;
    };

    /**
     * Splits the input text into an array of words.
     * @param {string} text - The text to be split.
     * @returns {string[]} An array of words.
     */
    private static splitText(text: string): string[] {
        return text.trim().split(/\s+/);
    }

    /**
     * Maps the text array to an array of WordObject, handling new line characters separately.
     * @param {string[]} textArray - The array of words to be mapped.
     * @returns {WordObject[]} An array of WordObject.
     */
    private static mapTextArray(textArray: string[]): WordObject[] {
        return textArray.flatMap((word) => {
            if (word.includes('\n')) {
                return word
                    .split(/(\n)/g)
                    .filter(Boolean)
                    .map((w) => MetaHelpers.mapWord(w));
            }
            return [MetaHelpers.mapWord(word)];
        });
    }

    /**
     * Maps a single word to a WordObject, identifying hashtags.
     * @param {string} word - The word to be mapped.
     * @returns {WordObject} The mapped WordObject.
     */
    private static mapWord(word: string): WordObject {
        if (word.startsWith('#')) {
            return { word, hashtag: true };
        }
        return { word };
    }

    /**
     * Filters and truncates the array of WordObject based on the maximum length and the showFullText flag.
     * @param {WordObject[]} textArray - The array of WordObject to be filtered.
     * @param {boolean} showFullText - Flag to indicate if the full text should be shown.
     * @param {number} maxLength - The maximum length of characters allowed before truncation.
     * @returns {WordObject[]} The filtered and truncated array of WordObject.
     */
    private static filterWords(textArray: WordObject[], showFullText: boolean, maxLength: number): WordObject[] {
        let characterCount = 0;
        let shouldAddMore = false;

        if (showFullText) return textArray;

        const filteredWordsArray = textArray.reduce<WordObject[]>((acc, wordObj) => {
            const length = wordObj.word.length;

            if (characterCount + length > maxLength && !shouldAddMore) {
                shouldAddMore = true;
                acc.push({ word: '...more', showMore: true });
                return acc;
            }

            if (!shouldAddMore) {
                characterCount += length;
                acc.push(wordObj);
            }

            return acc;
        }, []);

        if (shouldAddMore && !filteredWordsArray.some((word) => word.showMore)) {
            filteredWordsArray.push({ word: '...more', showMore: true });
        }

        return filteredWordsArray;
    }

    /**
     * Renders a single word object as a JSX element.
     * @param {WordObject} wordObj - The WordObject to be rendered.
     * @param {number} index - The index of the word object in the array.
     * @param {() => void} showFullTextCallback - Callback to show the full text.
     * @returns {JSX.Element} The rendered word as a JSX element.
     */
    private static renderWordFacebook(wordObj: WordObject, index: number, showFullTextCallback: () => void) {
        if (wordObj.hashtag) {
            return (
                <span key={wordObj.word + index} className="meta__text-format-facebook__hashtag">
                    {wordObj.word}{' '}
                </span>
            );
        } else if (wordObj.showMore) {
            return (
                <span key={wordObj.word + index} className="meta__text-format-facebook__show-more" onClick={showFullTextCallback}>
                    {wordObj.word}{' '}
                </span>
            );
        } else if (wordObj.word === '\n') {
            return <br key={index} />;
        } else {
            return <span key={wordObj.word + index}>{wordObj.word} </span>;
        }
    }

    /**
     * Renders a single word object as a JSX element.
     * @param {WordObject} wordObj - The WordObject to be rendered.
     * @param {number} index - The index of the word object in the array.
     * @param {() => void} showFullTextCallback - Callback to show the full text.
     * @returns {JSX.Element} The rendered word as a JSX element.
     */
    private static renderWordInstagram(wordObj: WordObject, index: number, showFullTextCallback: () => void) {
        if (wordObj.hashtag) {
            return (
                <span key={wordObj.word + index} className="meta__text-format-instagram__hashtag">
                    {wordObj.word}{' '}
                </span>
            );
        } else if (wordObj.showMore) {
            return (
                <span key={wordObj.word + index} className="meta__text-format-instagram__show-more" onClick={showFullTextCallback}>
                    {wordObj.word}{' '}
                </span>
            );
        } else if (wordObj.word === '\n') {
            return <br key={index} />;
        } else {
            return <span key={wordObj.word + index}>{wordObj.word} </span>;
        }
    }
}

export default MetaHelpers;
