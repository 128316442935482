import React, { useEffect, useState } from 'react';
import { Icon } from '@mui/material';
import BrandSettings from 'components/data/BrandSettings';
import SidebarLikes from '../images/sidebar-likes.svg';
import SidebarMessages from '../images/sidebar-messages.svg';
import SidebarShare from '../images/sidebar-share.svg';
import SidebarSave from '../images/sidebar-save.svg';
import FooterDiscover from '../images/footer-discover.svg';
import FooterInbox from '../images/footer-inbox.svg';
import FooterMe from '../images/footer-me.svg';
import FooterStart from '../images/footer-start.svg';
import LiveIcon from '../images/live-icon.svg';
import FooterTikTokIcon from '../images/footer-tiktok-icon.svg';
import { ITikTokData } from '../types/tiktok-preview.type';
import MetaHelpers from '../../meta/helpers/meta.helpers';

import '../styles/tiktok-feed-preview.scss';

const TikTokFeedPreview: React.FC<ITikTokData> = ({ cta, items }) => {
    const { displayName = '', adText = '', asset } = items?.[0] || {};
    const advertiser = BrandSettings.getAdvertiserData();
    const [showFullText, setShowFullText] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleShowFullText = () => {
        setShowFullText(true);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            document.querySelector('.tiktok-feed__content__cta')?.classList.add('animate');
        }, 2500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="tiktok-feed" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className="tiktok-feed__header">
                <div className="tiktok-feed__header__live-icon">
                    <img src={LiveIcon} />
                </div>
                <div className="tiktok-feed__header__text">
                    <div className="tiktok-feed__header__text__following">
                        Following
                        <div className="tiktok-feed__header__text__following__dot"></div>
                    </div>
                    <div className="tiktok-feed__header__text__for-you">For You</div>
                </div>
                <Icon className="tiktok-feed__header__search-icon">search</Icon>
            </div>
            <div className="tiktok-feed__asset-wrapper">{asset}</div>

            <div className="tiktok-feed__sidebar">
                <div className="tiktok-feed__sidebar__profile" style={{ backgroundImage: `url(${advertiser.logo})` }}></div>
                <div className="tiktok-feed__sidebar__icon">
                    <img src={SidebarLikes} />
                    62.1k
                </div>
                <div className="tiktok-feed__sidebar__icon">
                    <img src={SidebarMessages} />
                    511
                </div>
                <div className="tiktok-feed__sidebar__icon">
                    <img src={SidebarShare} />
                    617
                </div>
                <div className="tiktok-feed__sidebar__icon">
                    <img src={SidebarSave} />
                    617
                </div>
            </div>

            <div className={`tiktok-feed__footer ${isHovered ? 'hidden' : ''}`}>
                <div className="tiktok-feed__footer__icon">
                    <img src={FooterStart} />
                    Home
                </div>
                <div className="tiktok-feed__footer__icon">
                    <img src={FooterDiscover} />
                    Discover
                </div>
                <div className="tiktok-feed__footer__icon">
                    <img src={FooterTikTokIcon} style={{ marginBottom: 8 }} />
                </div>
                <div className="tiktok-feed__footer__icon">
                    <img src={FooterInbox} />
                    Inbox
                </div>
                <div className="tiktok-feed__footer__icon">
                    <img src={FooterMe} />
                    Me
                </div>
            </div>

            <div className={`tiktok-feed__content ${isHovered ? 'hidden' : ''}`}>
                <div className="tiktok-feed__content__headline">{displayName}</div>
                <div className="tiktok-feed__content__message">{MetaHelpers.transformText(adText || '', showFullText, handleShowFullText, 60)}</div>
                <div className="tiktok-feed__content__ad-label">Ad</div>
                <div className="tiktok-feed__content__cta">
                    {cta} <Icon>chevron_right</Icon>
                </div>
            </div>
        </div>
    );
};

export default TikTokFeedPreview;
