import { useEffect, useRef, useState, useCallback } from 'react';

const useProgressTimer = (currentIndex: number) => {
    const [progress, setProgress] = useState(0);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        setProgress(0);
        timerRef.current = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    if (timerRef.current) {
                        clearInterval(timerRef.current);
                    }
                    return 100;
                }
                return prevProgress + 2;
            });
        }, 100);

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [currentIndex]);

    const resetProgress = useCallback(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        setProgress(0);
    }, []);

    return { progress, resetProgress };
};

export default useProgressTimer;
